import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

import RecipeEditorialList from '../components/recipe-editorial-list';
import styled from 'styled-components';

const RecipeList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    list-style-type: none;
  }

  li:before {
    content: none;
  }
`;

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;
    const posts = data.allMarkdownRemark.edges;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Food I Made"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
          posts={posts}
        />
        <RecipeList>
          {posts.map(({ node }, idx) => {
            return (
              <li key={idx}>
                <RecipeEditorialList
                  recipe={node}
                  index={idx}
                ></RecipeEditorialList>
              </li>
            );
          })}
        </RecipeList>
      </Layout>
    );
  }
}

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fields: { path: { eq: "recipe" } }
        frontmatter: { draft: { ne: true } }
      }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            path
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 767) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
